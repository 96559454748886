import ReactGA4 from "react-ga4";

export const InitializeGoogleAnalytics = () => {
    ReactGA4.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    console.log("ReactGA4 INITIALIZED");
};

export const TrackGoogleAnalyticsEvent = (category, action, label) => {
    console.log("GA4 event:", category, ":", action, ":", label);

    ReactGA4.event({
        category: category,
        action: action,
        label: label,
    });
};
