import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import ReCAPTCHA from "react-google-recaptcha";
import "./App.css";
import data from "./company_tickers.json";
import CookiesBanner from "./CookiesBanner";
import {
    InitializeGoogleAnalytics,
    TrackGoogleAnalyticsEvent,
} from "./GoogleAnalytics";
// @note the \/ file, used as mock data for dev and testing
// import payload from "./submissions_CIK0000354950.json";
import Skeleton from "@mui/material/Skeleton";
import copy from "copy-to-clipboard";
import * as React from "react";
// @note the \/ lib, display entire payload data without extensive parsing
// import ReactJson from "react-json-view";
import packageJson from "../package.json";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// import Link from "@mui/material/Link";
import WarningIcon from "@mui/icons-material/Warning";
import Alert from "@mui/joy/Alert";
import Typography from "@mui/joy/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import Box from "@mui/joy/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";

import { CardActions } from "@mui/material";
import Cookies from "js-cookie";

// KISS

// @todo dev exp
// auto sort imports with add and rm checks on save
// css auto formatting

// @todo clean up global tasks
// check out initial load timing, is brutal
// theme, branding (generate logo?)
// typography app
// i18n
// check and polish options for all widgets
// misc css, CssBaseline
// cleanup in general
// seo, ref 1010rst
// resolve console warnings, fix React Hook React.useEffect has a missing dependency: 'regulatorCookieExpirationValue'. Either include it or remove the dependency array  react-hooks/exhaustive-deps
// ...

// @idea @future you could run a scrap backfill process, FFT

function App() {
    const packageJsonVersion = `BETA v${packageJson.version}`;
    const regulatorCookieName = "lookupein_regulator_cookie";
    const getRegulatorCookie = () => Cookies.get(regulatorCookieName);
    const hours = 23;
    const hoursInMinutes = 60 * hours;
    const dailySearchLimit = 5;
    const regulatorCookieExpirationValue = new Date(
        new Date().getTime() + hoursInMinutes * 60 * 1000
    );

    // @todo add conditional to check for env var to control values
    // to prevent pushing active sim data to live envs
    const simulator = {
        activated: false,
        showCopiedEinView: true,
        mockResponseEinData: {
            ein: 1313131313,
            phone: 1234567890,
            sicDescription: "Foo bar baz SIC company description",
        },
    };
    //
    const [searchFormSubmitted, setSearchFormSubmitted] = React.useState(false);
    const [fetchingEinData, setFetchingEinData] = React.useState(false);
    const [copiedToClipboardTooltipIsOpen, setCopiedToClipboardTooltipIsOpen] =
        React.useState(false);
    const [responseEinData, setResponseEinData] = React.useState({});
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [copiedToClipboardEvent, setCopiedToClipboardEvent] =
        React.useState(false);
    const [searchCountValue, setSearchCountValue] = React.useState(
        parseInt(getRegulatorCookie()) || dailySearchLimit
    );
    const [captchaValue, setCaptchaValue] = React.useState();

    // @todo how to handle EIN 000000000 use case
    // {/* @todo @groom issue when SEC renders EIN 000000000 value..., @idea add disable property and css `pointer` */}

    // @todo @groom handle dups, ex 10X CAPITAL VENTURE ACQUISITION CORP. III
    // each entity does have unique CIK
    // new workflow required to handle this use case
    // to then allow the user to select with of the N matches to search
    // may also require data aggregation to prove data points for user to help make right selection
    const companies = Object.entries(data)
        .map((x) => x[1])
        // title to uppercase
        .map((x) => {
            x.name = x.title.toUpperCase();

            return x;
        })
        // pad CIK with zeros
        .map((x) => {
            const offsetFill = 10 - x.cik_str.toString().length;

            // Central Index Key (CIK) 10-digit
            x.cik = Array(offsetFill).fill(0).join("") + x.cik_str;

            return x;
        })
        // add label property
        .map((y) => {
            y.label = `${y.name} (${y.ticker})`;

            return y;
        })
        // add key property
        .map((z, i) => {
            z.key = z.name + i;

            return z;
        })
        // sort companies by name
        .sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }

            // names must be equal
            return 0;
        });

    const onReCAPTCHAChange = (value) => {
        setCaptchaValue(value);
    };

    // Cookie handler
    React.useEffect(() => {
        const regulatorCookieValue = parseInt(getRegulatorCookie());
        if (!isNaN(regulatorCookieValue)) {
            setSearchCountValue(regulatorCookieValue || 0);
        }
        Cookies.set(regulatorCookieName, regulatorCookieValue, {
            // expires: 0.00005, // 30 sec // for manual in session testing
            expires: regulatorCookieExpirationValue,
            secure: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // "Copy to clipboard" handler
    React.useEffect(() => {
        let copiedToClipboardEventId;

        if (copiedToClipboardEvent) {
            copiedToClipboardEventId = setTimeout(() => {
                setCopiedToClipboardEvent(false);
                setCopiedToClipboardTooltipIsOpen(false);
            }, 1500);
        }
        return () => clearTimeout(copiedToClipboardEventId);
    }, [
        copiedToClipboardEvent,
        copiedToClipboardTooltipIsOpen,
        searchFormSubmitted,
    ]);

    // SEC API GET
    React.useEffect(() => {
        let setFetchingEinDataEventId;

        if (fetchingEinData) {
            setFetchingEinDataEventId = setTimeout(() => {
                setFetchingEinData(false);
            }, 1500);
        }
        return () => clearTimeout(setFetchingEinDataEventId);
    }, [fetchingEinData]);

    async function getSecSubmissionPayload(cik) {
        try {
            const endpoint = `https://data.sec.gov/submissions/CIK${cik}.json`;
            const response = await fetch(endpoint);
            const payload = await response.json();
            setResponseEinData(payload);
            return payload;
        } catch (error) {
            // @todo handle use case
            console.log("There was an error", error);
        }
        // @todo @optional handle use case
        // Uses the 'optional chaining' operator
        // if (response?.ok) {
        //     console.log('Use the response here!');
        //   } else {
        //     console.log(`HTTP Response Code: ${response?.status}`)
        //   }
    }

    // GA
    React.useEffect(() => {
        InitializeGoogleAnalytics();
    });

    return (
        <div className="App">
            {searchCountValue === 5 &&
                <CookiesBanner />
            }
            <Box style={{ padding: "2%" }}>
                <Container
                    maxWidth="xl"
                    disableGutters
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "2%",
                    }}
                >
                    <Card
                        style={{
                            flexGrow: 1,
                            flex: "1 1 0",
                            width: 0,
                            justifyContent: "center",
                            // cursor: "pointer",
                        }}
                        className="fade-in"
                    >
                        <CardHeader title="Lookup Employer Identification Number (EIN)" />
                        <CardContent style={{ paddingTop: "0" }}>
                            {/* @idea @future search as you type - https://mui.com/material-ui/react-autocomplete/#search-as-you-type */}
                            {/* @idea @future optimize search */}
                            <Autocomplete
                                /**
                                    @todo @groom "no results" option

                                    ? append entry object to `companies`
                                */
                                disableClearable={true}
                                options={companies}
                                size="small"
                                style={{ cursor: "pointer" }}
                                getOptionKey={(option) => option.key}
                                disabled={
                                    searchCountValue === 0 ||
                                    searchFormSubmitted
                                }
                                onChange={(event, newValue) => {
                                    setSelectedCompany(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search for Employer by Name"
                                    />
                                )}
                            />
                            {searchCountValue !== 0 && (
                                <>
                                    <Box sx={{ flex: 1 }}>
                                        <Box
                                            sx={{
                                                mt: 2,
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <ReCAPTCHA
                                                sitekey={
                                                    process.env
                                                        .REACT_APP_RECAPTCHA_SITE_KEY
                                                }
                                                onChange={onReCAPTCHAChange}
                                            />
                                        </Box>
                                    </Box>
                                    {/* @idea popover to explain limit - https://mui.com/material-ui/react-popover/ */}
                                    <Typography
                                        style={{ paddingTop: "2%" }}
                                        className="button-style-font-rip"
                                        level="body-xs"
                                    >
                                        Remaining Searches: {searchCountValue}{" "}
                                        out of {dailySearchLimit}
                                    </Typography>
                                </>
                            )}
                            {searchCountValue <= 0 && (
                                <Alert
                                    style={{ marginTop: "4%" }}
                                    variant="soft"
                                    color="warning"
                                    startDecorator={<WarningIcon />}
                                    sx={{
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <Box sx={{ flex: 1 }}>
                                        <Typography>
                                            You've reach the free daily search
                                            limit. Check back later or...
                                        </Typography>
                                        <Box
                                            sx={{
                                                mt: 2,
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <Button
                                                fullWidth={true}
                                                variant="contained"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    TrackGoogleAnalyticsEvent(
                                                        "requestMoreCreditsClearCookies",
                                                        "requestMoreCreditsClearCookiesClick",
                                                        "requestMoreCreditsClearCookiesClickLabel"
                                                    );
                                                    // @todo @future Request More Credits workflow
                                                    // for now, we just reset the viewport cookies
                                                    // which gives the actor 5 more credits
                                                    Cookies.remove(
                                                        regulatorCookieName
                                                    );
                                                    window.location.reload();
                                                }}
                                            >
                                                Request More Credits (CLEAR
                                                COOKIES)
                                            </Button>
                                        </Box>
                                    </Box>
                                </Alert>
                            )}
                        </CardContent>
                        <CardActions>
                            {searchCountValue !== 0 && (
                                <>
                                    <Button
                                        fullWidth={true}
                                        color="secondary"
                                        variant="contained"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            TrackGoogleAnalyticsEvent(
                                                "setSearchFormReset",
                                                "setSearchFormResetClick",
                                                "setSearchFormResetClickLabel"
                                            );
                                            setSearchFormSubmitted(
                                                !searchFormSubmitted
                                            );
                                            window.location.reload();
                                        }}
                                        disabled={
                                            !Object.keys(selectedCompany)
                                                .length ||
                                            searchCountValue === 0
                                        }
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        fullWidth={true}
                                        variant="contained"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            TrackGoogleAnalyticsEvent(
                                                "setSearchFormSubmitted",
                                                "setSearchFormSubmittedClick",
                                                "setSearchFormSubmittedClickLabel"
                                            );
                                            setFetchingEinData(
                                                !fetchingEinData
                                            );
                                            const nextSearchCountValue =
                                                searchCountValue - 1;

                                            setSearchCountValue(
                                                nextSearchCountValue
                                            );

                                            Cookies.set(
                                                regulatorCookieName,
                                                nextSearchCountValue,
                                                {
                                                    // @note leave \/ for dev and testing, @todo convert to ref control scope var in simulator
                                                    // expires: 0.00005, // 30 sec // for manual in session testing
                                                    expires:
                                                        regulatorCookieExpirationValue,
                                                    secure: true,
                                                }
                                            );

                                            getSecSubmissionPayload(
                                                selectedCompany.cik
                                            );

                                            setSearchFormSubmitted(
                                                !searchFormSubmitted
                                            );
                                        }}
                                        disabled={
                                            !Object.keys(selectedCompany)
                                                .length ||
                                            searchCountValue === 0 ||
                                            searchFormSubmitted ||
                                            !captchaValue
                                        }
                                    >
                                        Search
                                    </Button>
                                </>
                            )}
                        </CardActions>
                        <Typography
                            className="button-style-font-rip"
                            level="body-xs"
                            style={{ padding: "4% 2%" }}
                        >
                            {packageJsonVersion}
                        </Typography>
                    </Card>
                </Container>
            </Box>
            {((Object.keys(selectedCompany).length !== 0 &&
                searchFormSubmitted) ||
                simulator.activated) && (
                <Box style={{ padding: "0% 2% 2% 2%" }}>
                    <Container
                        maxWidth="xl"
                        disableGutters
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "2%",
                        }}
                    >
                        <Card
                            style={{
                                flexGrow: 1,
                                flex: "1 1 0",
                                width: 0,
                                justifyContent: "center",
                            }}
                            className="fade-in button-style-font-rip"
                        >
                            {fetchingEinData && (
                                <CardContent>
                                    <CircularProgress />
                                </CardContent>
                            )}
                            {!fetchingEinData && (
                                <>
                                    <CardContent>
                                        <Tooltip
                                            title="Click to copy to clipboard"
                                            placement="top"
                                            arrow
                                            followCursor
                                            open={
                                                !copiedToClipboardEvent &&
                                                copiedToClipboardTooltipIsOpen
                                            }
                                            onOpen={() => {
                                                setCopiedToClipboardTooltipIsOpen(
                                                    true
                                                );
                                            }}
                                            onClose={() => {
                                                setCopiedToClipboardTooltipIsOpen(
                                                    false
                                                );
                                            }}
                                            slotProps={{
                                                popper: {
                                                    modifiers: [
                                                        {
                                                            name: "offset",
                                                            options: {
                                                                offset: [
                                                                    0, -14,
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            }}
                                        >
                                            <Card
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    TrackGoogleAnalyticsEvent(
                                                        "copiedEinToClipboard",
                                                        "copiedEinToClipboardClick",
                                                        "copiedEinToClipboardClickLabel"
                                                    );
                                                    copy(
                                                        responseEinData.ein ||
                                                            simulator
                                                                .mockResponseEinData
                                                                .ein
                                                    );
                                                    setCopiedToClipboardEvent(
                                                        true
                                                    );
                                                    setCopiedToClipboardTooltipIsOpen(
                                                        false
                                                    );
                                                }}
                                                style={{
                                                    marginBottom: "2%",
                                                    alignItems: "center",
                                                    background:
                                                        copiedToClipboardEvent &&
                                                        "rgba(46,125,50, 0.3)",
                                                }}
                                            >
                                                {!copiedToClipboardEvent ? (
                                                    <Box
                                                        style={{
                                                            padding: "2%",
                                                        }}
                                                    >
                                                        <ContentCopyIcon
                                                            style={{
                                                                float: "right",
                                                            }}
                                                        />
                                                        <Typography level="title-lg">
                                                            EIN
                                                        </Typography>
                                                        <Typography level="body-md">
                                                            {responseEinData.ein ||
                                                                simulator
                                                                    .mockResponseEinData
                                                                    .ein}
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        style={{
                                                            padding: "1.5%",
                                                        }}
                                                    >
                                                        <Typography>
                                                            <CheckCircleIcon color="success" />
                                                        </Typography>
                                                        <Typography level="body-md">
                                                            Copied to clipboard
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Card>
                                            {/* <Typography level="body-sm">
                                                    Source:{" "}
                                                    <Link
                                                        href="https://www.sec.gov/"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        SEC
                                                    </Link>
                                                </Typography> */}
                                            {/* </Paper> */}
                                        </Tooltip>
                                        {/* SECTION: COMPANY DATA */}
                                        <Paper
                                            style={{
                                                padding: "4%",
                                                marginTop: "4%",
                                            }}
                                            elevation={10}
                                        >
                                            <Typography level="title-lg">
                                                Company Data
                                            </Typography>
                                            <Typography>
                                                {responseEinData.sicDescription ||
                                                    simulator
                                                        .mockResponseEinData
                                                        .sicDescription}
                                            </Typography>
                                            <Typography>
                                                {responseEinData.phone ||
                                                    simulator
                                                        .mockResponseEinData
                                                        .phone}
                                            </Typography>
                                            <Skeleton animation={false} />
                                            <Skeleton animation={false} />
                                            <Skeleton animation={false} />
                                            <Skeleton animation={false} />
                                            <Button
                                                fullWidth={true}
                                                color="secondary"
                                                style={{
                                                    marginTop: "4%",
                                                }}
                                                variant="outlined"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    TrackGoogleAnalyticsEvent(
                                                        "requestFullAccess",
                                                        "requestFullAccessClick",
                                                        "requestFullAccessClickLabel"
                                                    );
                                                    console.log(
                                                        "Request Full Access"
                                                    );
                                                }}
                                                disabled={true}
                                            >
                                                Request Full Access
                                            </Button>
                                        </Paper>
                                    </CardContent>
                                </>
                            )}
                        </Card>
                    </Container>
                </Box>
            )}
        </div>
    );
}

export default App;
